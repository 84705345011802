import React from "react";
import contactHeader from "../../assets/headers/contact-header.webp";
// import pawel from "../../assets/team/pawel.webp";
import Link from "../Link";

const ContactSection = ({ className }) => {
  return (
    <div className={`flex flex-col justify-start mx-8 ${className}`}>
      <img
        src={contactHeader}
        alt="Kontakt"
        className="object-contain h-[3.6106rem] lg:h-[4.9646rem] 2xl:h-[6.375rem] w-auto relative -top-4 lg:-top-5 2xl:-top-7"
      />
      <ul className="list-none w-full leading-relaxed mt-2 lg:mt-8 mb-14 lg:mb-4 h-auto lg:h-[11.01rem] 2xl:h-[17.3rem]">
        <li className="text-base 2xl:text-2xl">Menager zespołu</li>
        <li>Hubert Bałdo</li>
        <li className="mt-2">
          <Link href="tel:+48 577 213 835">+48 577 213 835</Link>
        </li>
        <li>
          <Link href="mailto:kontakt@zespolnsa.pl">kontakt@zespolnsa.pl</Link>
        </li>
      </ul>
    </div>
  );
};

export default ContactSection;
