import React from "react";

const SocialIcon = ({ href, children }) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className="inline-block my-[3rem] px-0"
    >
      <div className="drop-shadow-none hover:drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] transform hover:-translate-y-[3%] hover:scale-105 transition duration-300">
        {children}
      </div>
    </a>
  );
};

export default SocialIcon;
