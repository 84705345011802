import React from "react";

const Link = ({ href, children }) => {
  return (
    <a
      href={href}
      target={"_blank"}
      rel={"noreferrer"}
      className="drop-shadow-none hover:drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] transform hover:translate-x-[3%] hover:scale-105 block transition duration-300"
    >
      {children}
    </a>
  );
};

export default Link;
