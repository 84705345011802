import React from "react";
import Link from "../Link";
import downloadHeader from "../../assets/headers/download-header.webp";

const DownloadSection = ({ className }) => {
  return (
    <div className={`flex flex-col justify-start mx-8 ${className}`}>
      <img
        src={downloadHeader}
        alt="Do pobrania"
        className="object-contain h-[4rem] lg:h-[5.5rem] 2xl:h-[7.0625rem] w-auto relative -top-4 lg:-top-5 2xl:-top-7"
      />
      <ul className="list-none w-full leading-relaxed lg:mt-4 mb-14 lg:mb-4 h-auto lg:h-[11.01rem] 2xl:h-[17.3rem]">
        <li>
          <Link href="/do-pobrania/NSA-oferta-koncertowa-NSA-2024.pdf">
            Oferta koncertowa
          </Link>
        </li>
        <li>
          <Link href="/do-pobrania/NSA-oferta-koledy-2023_2024.pdf">
            Oferta kolędowa
          </Link>
        </li>
        <li>
          <Link href="/do-pobrania/rider">Rider techniczny</Link>
        </li>
        <li>
          <Link href="/do-pobrania/press-pack">Press pack</Link>
        </li>
      </ul>
    </div>
  );
};

export default DownloadSection;
