import React from "react";

const TeamImage = ({ src, alt, className }) => {
  return (
    <div className="absolute h-full">
      <img
        src={src}
        alt={alt}
        className={`relative bottom-0 h-full ${className}`}
        // loading={"lazy"}
      />
    </div>
  );
};

export default TeamImage;
